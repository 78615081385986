<template>
   <div class="suspend">
          <div class="bg_gray"></div>
          <div class="box">
              <headbox
                title="浮悬窗显示品种"
                bgColor="#39394D"
                :isbackPath="true"
                path="suspends"
              />
                 <div class="card">
                 <div class="quotedPrice flex_between" v-for="(item) in suspendCatgory" :key="item.id" @click="changeCatgory(item)">
                    <div class="text">{{item.name}}</div>
                    <van-icon name="success"  color="#39394D" v-if="item.def"/>
                 </div>
                 </div>
          </div>
   </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { mapState } from 'vuex'
export default {
  components: {
    headbox
  },
  data() {
    return {
    }
  },
    computed: {
     ...mapState(['suspendCatgory'])  
  },
  methods:{
    changeCatgory(item){
            this.suspendCatgory.forEach(i=>{
                i.def = !i.def
            })
            this.$emit('changeCatgory',item.id)
            localStorage.setItem('changeCatgoryName',item.id)
            localStorage.setItem('changeCatgory',JSON.stringify(this.suspendCatgory))
             this.$store.dispatch('getchangeSuspendCatgory',this.suspendCatgory)
            
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .van-icon{
    font-size: 40px !important;
  }
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.box{
  width: 100%;
  position: relative;
  z-index: 1;
    .card{
        width: 90%;
        margin: 40px auto 0;
        background-color: #fff;
        border-radius: 12px;
        padding: 0 20px;
        color: #212121;
        font-size: 28px;
    }
}
.flex_between{
  display: flex;
  justify-content: space-between;
  
}
.quotedPrice{
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.quotedPrice:last-child{
    border-bottom:none;
}
</style>